.effectsContainer {
  background-position-x: center;
  background-size: cover;
}
.shakeContainer {
  animation: shake 0.77s linear infinite;
  transform: translate3d(0, 0, 0);
}
.zoomContainer {
  /* animation: zoomIn 1s ease; */
  display: none;
}
.fadeContainer {
  /* opacity: 0; */
}
.backgroundContainer {
  overflow: hidden;
  z-index: -10;
  /* opacity: 0; */
}
.transitionsContainer, .backgroundContainer, .effectsContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) }
	10% { transform: translate(-1px, -1px) }
	20% { transform: translate(-1px, 0px) }
	30% { transform: translate(0px, 1px) }
	40% { transform: translate(1px, -1px) }
	50% { transform: translate(-1px, 1px) }
	60% { transform: translate(-2px, 1px) }
	70% { transform: translate(0px, 1px) }
	80% { transform: translate(1px, -1px) }
	90% { transform: translate(2px, 2px) }
	100% { transform: translate(1px, 2px) }
}