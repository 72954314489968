/** Socials List **/
.socials-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align:center;
}
.socials-list > li {
  display: inline-block;
  margin-left: 1em;
}
.socials-list > li:first-child {
  margin-left: 0em;
}
.socials-list a:link {
  color: rgba(255,255,255,0.75);
}
.socials-list a:hover {
  color: rgba(255,255,255,1);
}
.socials-list a:active, 
.socials-list a:visited {
  color: rgba(255,255,255,0.5);
}