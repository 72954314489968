.enter, .appear {
  opacity: 0;
  z-index: -2;
}
.enterActive, .appearActive {
  opacity: 1;
  transition: opacity 1000ms;
}
.enterDone, .appearDone {
  opacity: 1;
  z-index: -2;
}
.exit {
  opacity: 1;
  z-index: -3;
}
.exitActive {
  opacity: 0;
  transition: opacity 1000ms;
}
.exitDone {
  opacity: 0;
}