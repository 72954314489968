.enter {
  opacity: 0;
}
.enterActive {
  opacity: 1;
  transition: opacity 1000ms;
}
.enterDone {
  opacity: 1;
}
.exit {
  opacity: 1;
}
.exitActive, .exitActive:first-child {
  opacity: 0;
  transition: opacity 1000ms;
}
.exitDone, .exitDone:first-child {
  opacity: 0;
  display: none;
}