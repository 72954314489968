/** Footer **/
.footer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-end;
  margin: 0.5em;
}
.footer > * {
  text-align: center;
  width: 100%;
}
.copyright {
  font-size: small;
  margin: 0.5em;
}