/** Header **/
.header {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.logoWrapper {
  text-align: center;
  width: 100%;
  margin: auto;
}

/** Logo **/
.logo {
  display: inline-block;
  width: 200px;
  margin: 1em auto 0;
}

/** Navbar **/
.nav {
  margin: 0 auto 1em;;
  text-align: center;
  /* padding: 1em; */
  font-family: 'bondieregular';
  font-size: 1.3rem;
}
.nav a {
  display: inline-block;
  padding: 0.5em 0.75em;
  margin-left: 0.15em;
  margin-right: 0.15em;
  margin-bottom: 0.5em;
  /* border: 1px solid; */
  text-decoration: underline;
}
.nav a:link, .nav a:visited {
  color: #FFF;
  background-color: rgba(0,0,0,0.6);
  font-size: 1.1em;
}
.nav a:hover {
  color: #000;
  background-color: rgba(255,255,255,0.6);
}
.nav a:active {
  background-color: rgba(255,255,255,0.9);
}