.appear,
.enter {
  transform: scale(2);
  z-index: -1;
  display: block;
}

.appearActive,
.enterActive {
  transform: scale(1.01);
  transition: transform 1000ms;
  z-index: -1;
  display: block;
}

.appearDone,
.enterDone {
  transform: scale(1.01);
  z-index: -3;
  display: block;
}
/* .exit {
  transform: scale(1);
}
.exitActive {
  transform: scale(0);
  transition: transform 1000ms;
}
.exitDone {
  transform: scale(0);
} */