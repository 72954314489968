/** Containing elements **/
.wrapper {
  display: flex;
  flex-wrap: wrap;
  min-height: 100%;
  flex-direction: column;
}
.main {
  display: flex;
  flex-grow: 0.001;
  flex-direction: column;
  align-items: center;
  transition: height 2s ease;
  align-self: center;
  margin: auto;
  max-width: 100%;
  transition: flex-grow 1s, background 1s;
}
.mainTop {
  flex: 1;
  background: rgba(0,0,0,0.75);
}
.mainCenter {
  background: rgba(0,0,0,0.45);
  text-align: center;
}
.content {
  padding: 0 4em;
  width: calc(550px + 8em);
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  max-height: 0;
  transition: max-height 1s cubic-bezier(1, 0.24, 1, -0.01);
}
.mainTop .content {
  max-height: 10000px;
}