.slide {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
.slide:hover {
  cursor: pointer;
}
.slide:first-child {
  opacity: 1;
}

.slidesContainer {
  overflow: hidden; 
  position: relative;
  max-height: 60vw;
}