html {
  background-color: black;
  color: #EEE;
  font-family: Georgia, 'Times New Roman', Times, serif;
  line-height: 1.5;
  font-size: 14pt;
}

html, body, #root {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
}

/** Background zoom. Needed to be global to fix jitter after hydrate **/
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -30;

  background-image: url('./img/bg/home.jpg');
  background-position-x: center;
  background-size: cover;
  animation: zoomIn 1s ease;
}

@keyframes zoomIn {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1.01);
  }
}
/** end background zoom transition **/

/** TEXT STYLES **/
@font-face {
  font-family: 'bondieregular';
  src: url('./fonts/bondie-webfont.woff2') format('woff2'),
       url('./fonts/bondie-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

h1, h2, h3, h4 {
  margin: 0;
  font-family: 'bondieregular';
  font-size: 5em;
  font-weight: normal;
  text-align: center;
  text-transform: lowercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link {
  color: rgb(145, 202, 255);
}
a:visited {
  color: rgb(95, 131, 161);
}

img {
  max-width: 100%;
  margin: 0;
  border: 0;
  padding: 0;
}
/** FONT AWESOME OVERRIDES **/
.fa-ul {
  line-height: 1;
}
.fa-ul > li {
  margin: 0.5em 0;
}
/** END FONT AWESOME **/

/** FORMS **/
label {
  display: block;
}

textarea {
  height: 8em;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
}
textarea, input[type="text"], input[type="email"] {
  width: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12pt;
  padding: 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.35);
  color: white;
  box-sizing: border-box;
}

input[type="submit"] {
  font-family: 'bondieregular';
  font-size: 2em;
  color: white;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 16px 32px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}
input[type="submit"]:active {
  border-color: white;
  background-color: rgba(255, 255, 255, 0.35);
}